import React, { useState } from "react";

import BasicSection from "../components/BasicSection/BasicSection";
import HeroSection from "../components/HeroSection/HeroSection";
import BreadcrumbsComponents from "../components/BreadCrumbs/BreadCrumbs";
import { Col, Row, Button, Spinner } from "react-bootstrap";

import { Link, useParams } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import axios from "axios";

export default function BlogsFinancialConsulting() {
  interface RouteParams {
    id: string;
  }
  interface Category {
    id: number;
    name: string;
    image: string;
    name_ar: string;
    name_en: string;
  }
  interface Data {
    id: number;
    image: string;
    title_ar: string;
    title_en: string;
    description_ar: string;
    description_en: string;
    short_description_ar: string;
    short_description_en: string;
    category_id: number;
    name_ar: string;
    name_en: string;
    active: number;
    add_date: string;
  }
  interface BlogData {
    items: Data[];
  }

  const { id } = useParams<RouteParams>();
  const [blogs, setBlogs] = React.useState<BlogData>({ items: [] });
  const [updatedId, setUpdatedId] = React.useState<number>();
  const [blogDetails, setBlogDetails] = React.useState<Data>({
    id: 0,
    image: '',
    title_ar: '',
    title_en: '',
    description_ar: '',
    description_en: '',
    short_description_ar: '',
    short_description_en: '',
    category_id: 0,
    name_ar: '',
    name_en: '',
    active: 0,
    add_date: '',
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [readMore, setReadMore] = useState(false);

  const toggleReadMore = () => {
    setReadMore(!readMore);
  };

  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng") || "en";

  React.useEffect(() => {
    document.title = t("Cloud Secrets") + " - " + t("Blog")
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [t]);

  React.useEffect(() => {
    getBlogDetails()
    !blogs?.items?.length && getBlogs()
  }, [updatedId]);

  const endPoint = 'https://api.cloudsecretsksa.com'
  const token = 'Bearer sFeWbKwiBcO5Fbw22lMzVgK9UWL2jDPY57iAT14zuzfeyfCq1vVUYSYNpekQGlMTg2CUV6ZOH4vfiJ8EMjLps6GvLlHikIvOw9DUO8YvSPQF4l6hwWf9QqEr6nv4Feq2xGM7fbTwnkDdkTaCsJZGPSzQkLkafZC0j4k8QtjRe9ReSRc2ntpJm4mlozaHI2cz4jpbEgvd'
  const getBlogDetails = () => {
    blogs?.items?.length && setLoading(true)
    axios.get(`${endPoint}/api/v1/${lang}/front/get_blog/${updatedId || id}`, {
      headers: {
        'Access-Token': token
      }
    }).then((response) => {
      console.log('getBlogDetails', response.data.data.data);
      setLoading(false)
      if (response.data.check) {
        setBlogDetails(response.data.data.data)
      }
    }).catch((error) => {
      setLoading(false)
      console.log(error);
    });
  }

  const getBlogs = () => {
    // let params = '?rows=10&page=1&word=&category_id=4'
    axios.get(`${endPoint}/api/v1/${lang}/front/blogs`, {
      headers: {
        'Access-Token': token
      }
    }).then((response) => {
      console.log('response', response.data.data);
      if (response.data.check) {
        setBlogs(response.data.data)
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  return (
    <section>

      <HeroSection title={lang === 'ar' ? blogDetails?.title_ar : blogDetails?.title_en}
        body={lang === 'ar' ? blogDetails?.name_ar : blogDetails?.name_ar} backgroundImg={endPoint + blogDetails?.image}
        backgroundRepeat="no-repeat" backgroundSize="cover" heightAuto="calc(50vh + 150px)" withOverlay={true} />

      <BreadcrumbsComponents activePage="Financial Consulting" parent="Blog" parentLink="/blogs" />

      <Row className="m-0">
        {loading ?
          <Col lg={9} xs={12} className="p-5">
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignContent: 'center', textAlign: 'center', height: '40dvh' }}>
              <div><Spinner animation="grow" /></div>
              <div> {t("loading data")}</div>
            </div>
          </Col>
          :
          <Col lg={9} xs={12} className="p-5">
            <h3 className={readMore ? 'expanded' : 'line-clamp'} dangerouslySetInnerHTML={{ __html: lang === 'ar' ? blogDetails?.short_description_ar : blogDetails?.short_description_en }} />
            <p className={readMore ? 'expanded' : 'line-clamp'} dangerouslySetInnerHTML={{ __html: lang === 'ar' ? blogDetails?.description_ar : blogDetails?.description_en }} />
            <div className="mt-5" style={{ display: 'flex', justifyContent: 'center' }}>
              {blogs?.items?.length ? <Button onClick={toggleReadMore}>{readMore ? t("Hide") : t("Read more")}</Button> : ''}
            </div>
          </Col>
        }

        <Col lg={3} xs={12} className="px-5" style={{ overflowY: 'scroll', height: '80vh' }}>
          {blogs?.items?.length ? blogs?.items.map((item) => (
            <Link key={item.id} to={`/blogs/financial-consulting/${item.id}`} onClick={() => setUpdatedId(item.id)}>
              <div className="suggested-blog-card" style={{ marginBottom: '20px', cursor: 'pointer', position: 'relative', color: 'white', marginTop: '20px' }}>
                <img src={endPoint + item.image} alt={item.title_en} style={{ width: '100%', height: '200px', objectFit: 'cover', borderRadius: '8px' }} />
                <div style={{ position: 'absolute', bottom: '10px', left: '10px', right: '10px', backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '10px', borderRadius: '8px', color: 'white' }}>
                  <h5 style={{ fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'white' }}>{lang === 'ar' ? item.title_ar : item.title_en}</h5>
                  <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'white' }} dangerouslySetInnerHTML={{ __html: lang === 'ar' ? item.short_description_ar : item.short_description_en }} />
                </div>
              </div>
            </Link>
          )) : ''}
        </Col>

      </Row>

    </section>
  );
}
