import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./HeroSection.scss";
import mouseScrollImg from "../../assets/png/icons/scroll.gif";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
interface HeroSectionPropTypes {
  title: string;
  body: string;
  linkText?: any;
  link?: string;
  linkOut?: string;
  backgroundImg?: string;
  backgroundAttachment?: "initial" | "fixed";
  backgroundColor?: string;
  mouseScroll?: boolean;
  heightAuto?: string;
  backgroundRepeat?: string,
  backgroundSize?: string,
  withOverlay?: boolean; // New prop to control the overlay
}
const HeroSection: React.FC<HeroSectionPropTypes> = ({
  title,
  body,
  linkText,
  link,
  linkOut,
  backgroundImg,
  backgroundColor,
  backgroundAttachment = "fixed",
  mouseScroll = true,
  heightAuto = "100vh",
  // backgroundRepeat = "",
  backgroundSize = "",
  withOverlay = false,
}) => {
  const { t } = useTranslation();
  const scrollToTop = () => {
    window.scrollTo({
      top: +680,
      behavior: "smooth",
    });
  };
  return (
    <>
      <section
        className="heroSection"
        style={
          backgroundImg || heightAuto
            ? {
              backgroundImage: `url('${backgroundImg}') `,
              backgroundAttachment: backgroundAttachment,
              backgroundSize: backgroundSize,
              height: `${heightAuto}`,
            }
            : {}
        }
      >
        <Container fluid  className={withOverlay ? "with-overlay" : ""}>
          <Row className="justify-content-center row_content" >
            <Col lg={7} xs={11}>
              <div className="my-5">
                <h2 className="text-center  my-3" data-aos="zoom-in">{t(title)}</h2>
                <h6 className="text-center my-5" data-aos="zoom-in">{t(body)}</h6>
                {link && <NavLink data-aos="zoom-in" to={link}>{t(linkText)}</NavLink>}
                {linkOut && <a data-aos="zoom-in" href={linkOut}>{t(linkText)}</a>}
              </div>
              {mouseScroll && (
                <>
                  <img
                    src={mouseScrollImg}
                    alt="cloud_secrets"
                    className="mouseScroll"
                    onClick={scrollToTop}
                  />
                  <p className="text-white text-center">
                    <small>{t("scroll down")}</small>
                  </p>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default HeroSection;
