import * as React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  ToastContainer,
  Toast,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./ContactUsSection.scss";
const baseURL = "https://api.cloudsecretsksa.com/api/v1/en/front/contact_us"; /* old api http://cs-api.cs-testing.com */
export function ContactUsSection() {
  const { t } = useTranslation();

  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [message, setMessage] = React.useState(String);
  const [toastValue, setToast] = React.useState(Boolean);
  const [isValid, setIsValid] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const sendContact = () => {
    setIsLoading(true);
    axios
      .post(baseURL, { name: name, phone: phone, description: description })
      .then((response: any) => {
        setIsLoading(false);
        if (response.data.check) {
          setMessage(response.data.msg)
          setToast(true)
          resetContact();
        } else {
          setToast(true)
          setMessage(response.data.msg)
        }
      }).catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  const resetContact = () => {
    setPhone("");
    setName("");
    setDescription("");
    setIsValid(false);
    setIsLoading(false);
  };

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };
  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  };
  const handlePhoneChange = (event: any) => {
    setPhone(event.target.value);
  };
  React.useEffect(() => {
    return () => {
      if (name && description && phone) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    };
  });

  return (
    <>
      <section className="contactUsSection px-0">
        <Container fluid className="p-0">
          <Container>
            <Row className="justify-content-between align-items-center p-0">
              <Col xl={5} lg={6} xs={12} className="p-5">
                <h3 className="text-center text-white mt-5">
                  {" "}
                  {t("Contact us")}
                </h3>
              </Col>
              <Col xl={5} lg={6} xs={12} className="p-3" data-aos="flip-up">
                <Card className="border-0 bg-white rounded-0 p-5">

                  <Form.Group className="mb-4" controlId="formBasicName">
                    <Form.Control
                      type="name"
                      value={name}
                      onChange={handleNameChange}
                      placeholder={t("name")}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="formBasicPhone">
                    <Form.Control
                      type="number"
                      value={phone}
                      onChange={handlePhoneChange}
                      placeholder={t("Phone Number")}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="formBasicName">
                    <Form.Control
                      type="description"
                      value={description}
                      onChange={handleDescriptionChange}
                      placeholder={t("description")}
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    size="lg"
                    className="w-100 my-4 rounded-0"
                    onClick={sendContact}
                    disabled={!isValid || isLoading}
                  >
                    {isLoading ? t("Sending…") : t("Send")}
                  </Button>
                </Card>
              </Col>
            </Row>
            <ToastContainer className="p-3" position="bottom-center">
              <Toast onClose={() => setToast(false)} delay={4000} show={toastValue} autohide>
                <Toast.Body>
                  <strong> {message}</strong>
                </Toast.Body>
              </Toast>
            </ToastContainer>
          </Container>
        </Container>
      </section>
    </>
  );
}
export default ContactUsSection;
